$(document).ready(function() {
/* ----------- filtro para páginas e notícias na busca do site ----------- */
  $('#searchbox_currentfolder_only').change(function() {
    var form = $('#searchGadget_form');
    var select = $(this);
    var option = select.val();
    // garante que não há nenhum input hidden adicionado
    form.find('input[type=hidden]').remove();
    form.append('<input type="hidden" name="selected" value="' + option + '"> ');
    if ( option != '' && option.search('/') < 0 ) {
      // altera o name do select se for notícia, página ou arquivo
      select.attr('name','portal_type:list');
      // adiciona outros filtros
      if (option == 'Document') {
        // Pasta quando selecionado Página
        form.append('<input type="hidden" name="portal_type:list" value="Folder"> ');
      }
      else if (option == 'News Item') {
        // Ordena por data quando for notícias
        form.append('<input type="hidden" name="sort_on" value="Date"> ');
      }
    }
    else {
      // altera o name do select se for 'Todo site' ou 'Nesta seção'
      select.attr('name','path');      
    }
  });
});