$(document).ready(function() {
    setTimeout(() => {
        const links = document.querySelectorAll('a');

        links.forEach(link => {
            // Check if the <a> tag contains an <img>
            const imgInside = link.querySelector('img');
            if (imgInside) {
                // Check if the previous sibling is an <svg> tag
                const previousElement = link.previousElementSibling;
                if (previousElement && previousElement.tagName.toLowerCase() === 'svg') {
                    // console.log('SVG tag found before <a> containing <img>:', link);
                    previousElement.style.display = 'none';
                }
            }
        });
    }, 500); // Adjust the delay as needed
});


