/* ----------- Chat TJDFT ----------- */
$(document).ready(function() {
    const PORTAL_URL=document.body.getAttribute("data-portal-url");
    const allowedUrls = [
        PORTAL_URL+"/",
        PORTAL_URL+"/pje",
        PORTAL_URL+"/consultas/processuais",
        PORTAL_URL+"/atendimento-ao-publico",
        PORTAL_URL+"/ouvidoria",
        PORTAL_URL+"/atendimento-ao-publico/chat-do-tjdft"        
    ];
    if (allowedUrls.includes(window.location.href)) {
        if (window.location.href == PORTAL_URL+"/"){
            pai = $("#pagina-inicial")
        } else{
            pai = $("#content-core")
        }
        pai.append("<div id='chat-tjdft'><a class='btn-flutuante-container' href='https://chat.tjdft.jus.br/chat/'> <span class='btn-flutuante dois'></span><span class='btn-flutuante um'>Posso ajudar?</span></a></div>");
    }
});
