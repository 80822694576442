$(document).ready(function() {
  /* ----------- Mostra busca no mobile ----------- */
  $('.icone-busca').click(function() {
      $('#portal-searchbox').removeClass('d-none');
      $('.icone-busca').hide();
      $('#searchGadget').focus();
    });
    // foca no campo busca ao exibir
    $('#searchbox_currentfolder_only').change(function() {
      $('#searchGadget').focus();
    });
});
