/* Adiciona /view quando o tipo banner for acessado na listagem de conteúdo */
// executa apenas quando usuário autenticado
$(document).ready(function() {
  if ( $('.userrole-authenticated').length ) {
    // como a tabela é montada via ajax, escuta toda requisição para inserir o /view
    $(document).ajaxStop(function(){
      $('[id*=DataTables_Table_] .contenttype-banner').each(function(){ 
        var url =  $(this).attr('href');
        if ( url.indexOf('/view') < 0 ) {
          $(this).attr('href', url + '/view');
        }
      });
    });
  }
});
