$(document).ready(function() {
  var filters = $('#search-filter input[type=checkbox]')
  if ( filters.length ) {
    // recupera os filtros selecionados pelo usuário 
    var selectedFilters = [];
    $('#searchGadget_form [name="portal_type:list"]').each(function(){
      selectedFilters.push($(this).val());
    });
    // verifica se busca foi feita usando o Nesta Seção
    var path = $('#searchGadget_form [name="selected"]').val();
    if ( path.search('/') >= 0 ) {
        // adicona campo na busca corrigindo ausência desse parametro pelo Plone
        var section = path.split('/');
        section = section[section.length - 1]
        $('#searchform .search-type-options').append('<div><input type="checkbox" name="path" value="' + path + '" class="noborder" checked="checked" id="portal_path"><label for="portal_path">Nesta Seção (' + section + ')</label></div>');
    }
    // remove os filtros não selecionados pelo usuário
    filters.each(function(){
        if ( path.search('/') < 0 && path != '' ) {
            if ( $.inArray($(this).val(), selectedFilters) < 0 ) {
                this.checked = false;
            }
        }
    });
  }
});