$(document).ready(function() {
/* ----------- mapa do site ----------- */
  $('.mapa-site').click(function(){
    var mapa = $('#tjdft-portal-sitemap');
    var txt = $('.mapa');
    var filtro = $('#mapa-filtro');
    mapa.toggle();
    if ( mapa.css('display') == 'block' ) {
      filtro.show();
      filtro.children().focus();
      txt.text('ocultar mapa do site');
      $('.bi-chevron-down').addClass('d-none');
      $('.bi-chevron-up').removeClass('d-none');
    }
    else {
      filtro.hide();
      txt.text('exibir mapa do site');
      $('.bi-chevron-up').addClass('d-none');
      $('.bi-chevron-down').removeClass('d-none');
    }
    return false;
  });
  // filtro
  $('#mapa-filtro input').on('keyup', function() {
    var value = $(this).val().toLowerCase();
    $('#tjdft-portal-sitemap li').filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      $('#tjdft-mapa-site').addClass('filtro');
      if ( value.length == 0 ) {
        $('#tjdft-mapa-site').removeClass('filtro');
      }
    });
  });
  // botão para limpar
  $('.mapa-limpa').click(function(){
    var filtro = $('#mapa-filtro input');
    filtro.val('');
    filtro.trigger('keyup');
    filtro.focus();
  });
});
