$(document).ready(function() {
/* ----------- Menu principal ----------- */
  var selected = $('#portal-globalnav .selected');
  function showItem( elem ) {
    $('#portal-globalnav .selected').removeClass('selected');
    elem.show();
    elem.prev().removeClass('glyphicon-menu-down') ;
    elem.prev().addClass('glyphicon-menu-up') ;
    elem.prev().parent().addClass('selected') ;
  }
  function hideItem( elem ) {
    if (elem === undefined)
      return;
    clearTimeout(timeout);
    elem.hide();
    elem.prev().removeClass('glyphicon-menu-up') ;
    elem.prev().parent().removeClass('selected') ;
    elem.prev().addClass('glyphicon-menu-down') ;
    selected.addClass('selected');
  }
  function hasTouch() {
    return 'ontouchstart' in document.documentElement
          || navigator.maxTouchPoints > 0
          || navigator.msMaxTouchPoints > 0;
  }
  var submenu;
  if ( !hasTouch() ) {
    var timeout;
    var menu = $('.menu-principal').parent();
    menu.hover(
      function() {
        submenu = $(this).find('.tjdft-navTreeLevel1');
        selected.removeClass('selected');
        timeout = setTimeout(function(){
          showItem(submenu);
        }, 500);
      }, function() {
        hideItem(submenu);
      }
    );
  }
  // inibe navegação ao clicar no item topo do menu principal
  // faz funcionamento accordion no menu principal
  $('.menu-principal').click(function() {
    submenu = $(this).next();
    if ( submenu.css('display') == 'none' ) {
      $('#portal-globalnav .tjdft-navTreeLevel1').hide();
      showItem(submenu);
    }
    else {
      hideItem(submenu);
    }
    return false;
  });
});
