$(document).ready(function() {
  /* ----------- Máscara CPF e Data ----------- */
  var exists_cpf_data = $('input.data, input.cpf').length;
  if ( exists_cpf_data ) {
    const PORTAL_URL=document.body.getAttribute("data-portal-url");
    $.getScript(PORTAL_URL+'/++plone++tjdft/jquery.mask.min.js', function() {
      $('input.data').mask('99/99/9999',{placeholder:'dd/mm/aaaa'});
      $('input.cpf').mask('999.999.999-99',{placeholder:'___.___.__-__'});
    });
  }
});
