$(document).ready(function() {
  /* ----------- Galeria de Imagens ----------- */
  const PORTAL_URL=document.body.getAttribute("data-portal-url");
  var galeria = $('.galeria');
  if ( galeria.length){
    $.getScript(PORTAL_URL+'/++plone++tjdft/simpleLightbox.min.js', function() {
      $('.template-album_view .card-body a')
      $('<link/>', {
        rel: 'stylesheet',
        type: 'text/css',
        href: '/++plone++tjdft/simpleLightbox.min.css'
      }).appendTo('head');

      // define atributos que serão o link e descrição da imagem
      galeria.each(function(){
        var link = $(this).find('.galeria-link').attr('href').split('/view',1);
        $(this).attr('data-href', link);
        $(this).attr('title', $(this).find('.galeria-titulo').text());
      });

      // inicia plugin
      galeria.simpleLightbox(
        defaults = {
          closeBtnCaption: 'Fechar',
          nextBtnCaption: 'Próximo',
          prevBtnCaption: 'Anterior',
          loadingCaption: 'Carregando...',
          urlAttribute: 'data-href'
        });
    });
  }
});
